import React from "react";
import './Home.css';
import Timeline from '../../components/timeline/TimeLine';
import Footer from '../../components/footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
import { Suspense,useEffect, useRef, useState } from 'react';
import { Parallax, ParallaxLayer } from '@react-spring/parallax';
import { Link } from 'react-router-dom';
import FallBack from "../../components/fall_back/FallBack";


const Skills = React.lazy(()=>import("../../components/skills"));
const Hero = React.lazy(()=>import("../../components/hero"));
const Gallery = React.lazy(()=>import("../../components/gallery"));
const TransitionOne=React.lazy(()=> import('../../components/transition1'));
const TransitionTwo =React.lazy(()=> import( '../../components/transition2'));
const TransitionThree  =React.lazy(()=> import('../../components/transition3'));

class ErrorBoundary extends React.Component{
  constructor(props){
    super(props);
    this.state={hasError:false};
  }

  static getDerivedStateFromError(error){
    return {hasError:true};
  }

  componentDidCatch(error,info){
    console.log(error, info);
  }

  render(){
    if(this.state.hasError){
      return (
        <FallBack />
      )
    }
    return this.props.children;
  }
}

function Home({inSkillComponent,setInSkillComponent,ajLogoRef}) {

  const mainDivRef = useRef();
  const [selected,setSelected] = useState(false);

  useEffect(()=>{
    AOS.init({
      duration:1000
    })
  },[]);

  async function handleEventListener(){
      var div =await document.getElementById('mainDiv');
      var scrollTop = await div.scrollTop;
      var height =await div.clientHeight;
      ajLogoRef.current.style.transform = "rotate3d(0,1,0,"+((scrollTop/height)*360)+"deg)";
  }

  useEffect(()=>{
    // handleEventListener();
    document.getElementById('mainDiv').addEventListener('scroll',handleEventListener);
    return ()=>{
      document.getElementById('mainDiv').removeEventListener('scroll',handleEventListener);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  // ajx gallery { position: [x, y, z],scaleC:[w, h, 0.01],landscape:true, rotation: [0, Math.PI / 4, 0], url:`/images/cooking/4.jpg` },
  const images = [
  { position: [0, 0, -2],scaleC:[0.75, 1.0, 0.01],landscape:true, rotation: [0, 0, 0], url:`/images/gallery/photography/28.jpg` },

  // Left up 
  { position: [-3.8, 2.7, 1.2],scaleC:[1.0*1.2, 0.83125*1.2, 0.01], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/4.jpg` },
  { position: [-2.9, 3, .5],scaleC:[0.93125, 1.0, 0.01], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/6.jpg` },
  { position: [-2, 2.8, 0],scaleC:[1.0, 0.75, 0.01], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/9.jpg` },
  { position: [-1.2, 1.6, -1.1],scaleC:[0.965625, 1.0, 0.01],landscape:true, rotation: [0, Math.PI / 4, 0], url: `/images/gallery/cooking/13.jpg` },
  { position: [-2, 1.7, -0.4],scaleC:[1, 1.3, 0.01],landscape:true, rotation: [0, Math.PI / 4, 0], url: `/images/gallery/cooking/3.jpg` },
  { position: [-2.9, 1.5, .5],scaleC:[4.725/4, 6.3/4, 0.01],landscape:true, rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/11.jpg` },
  { position: [-3.8, 1.3, 1.4],scaleC:[2.25/2, 2.4/2, 0.02], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/1.jpg` },
  { position: [-4.5, 1.8, 2.2],scaleC:[0.853125, 1.0, 0.02], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/2.jpg` },
  
  // // Left down 
  { position: [-4.5, .7, 2.2],scaleC:[1.0, 0.75, 0.02], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/passion/1.jpg` },
  { position: [-3.8, 0, 1.4],scaleC:[1.0, 1, 0.01], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/passion/2.jpg` },
  { position: [-1.1, 0, -1.4],scaleC:[1.0, 0.840625, 0.01], rotation: [0,Math.PI / 4, 0], url:`/images/gallery/cooking/10.jpg` },
  { position: [-1.8, .2, -.3],scaleC:[4.725/4, 6.3/4, 0.01], rotation: [0, Math.PI / 4, 0], url:`/images/gallery/cooking/8.jpg` },
  { position: [-2.8, -.1, .4],scaleC:[2.25/2, 2.80/2, 0.01], rotation: [0, Math.PI / 4, 0], url: `/images/gallery/passion/3.jpg` },



  // Right up 
  { position: [3.7, 3, 1.6],scaleC:[2.25, 1.265625, 0.01], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/passion/4.jpg` },
  { position: [2.6, 2.8, .6],scaleC:[0.75, 1.0, 0.01], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/passion/6.jpg` },
  { position: [2, 2.8, 0],scaleC:[0.75, 1.0, 0.01], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/photography/25.jpg` },
  { position: [1.2, 2.5, -1.1],scaleC:[2.9984375/2, 1.6875/2, 0.01],landscape:true, rotation: [0, -Math.PI / 4, 0], url: `/images/gallery/photography/10.jpg` },
  { position: [1.55, 1.5, -0.7],scaleC:[1.5, 0.84375, 0.01],landscape:true, rotation: [0, -Math.PI / 4, 0], url: `/images/gallery/photography/6.jpg` },
  { position: [2.9, 1.5, .5],scaleC:[1.5*1.4, 1.0*1.4, 0.01],landscape:true, rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/photography/1.jpg` },
  { position: [2.9, 1.5, .4],scaleC:[1.5, 0.84375, 0.01],landscape:true, rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/photography/4.jpg` },
  { position: [4.1, .9, 1.7],scaleC:[1.0, 0.5625, 0.01], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/photography/8.jpg` },
  { position: [3.9, 1.8, 1.6],scaleC:[0.75, 1.0, 0.02], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/passion/8.jpg` },
  { position: [4.5, 1.8, 2.2],scaleC:[1, 1.1, 0.02], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/passion/7.jpg` },
  
  // Right down 
  { position: [1, .2, -1.5],scaleC:[3.0625/4, 6.3/4, 0.01], rotation: [0,-Math.PI / 4, 0], url:`/images/gallery/photography/5.jpg` },
  { position: [1.75, .2, -.6],scaleC:[1.0, 1.5, 0.01], rotation: [0, -Math.PI / 4, 0], url:`/images/gallery/photography/2.jpg` },
  { position: [2.6, -.1, .4],scaleC:[2.25/2,  2.81/2, 0.01], rotation: [0, -Math.PI / 4, 0], url: `/images/gallery/photography/21.jpg` },
  { position: [3.7, 0, 1.8],scaleC:[1.0*2, 0.459375*2, 0.01],landscape:true, rotation: [0, -Math.PI / 4, 0], url: `/images/gallery/photography/27.jpg` },
  
  // { position: [2.25, 0, 1.3],scaleC:[0.75, 1.0/2.2, 0.01], rotation: [0, -Math.PI / 4, 0], url: `/images/gallery/photography/25.jpg` },
  ];

  useEffect(()=>{
    async function handleSelect(){
      const h2 =await document.getElementById("galleryH2");
      if (h2){
        if (selected)
        {
          h2.style.opacity=0
        }
        else{
          h2.style.opacity=1
        }
      }
    }
    handleSelect();

  },[selected]);

 const handleScroll = async(divId)=>{
   const windowTop = await divId.scrollTop;
   const transitionDiv1Id =await document.getElementById("transitionDiv1Id");
   const transitionDiv2Id =await document.getElementById("transitionDiv2Id");
   const transitionDiv3Id =await document.getElementById("transitionDiv3Id");
   const eleTop1 = await transitionDiv1Id.offsetTop;
   const eleTop2 = await transitionDiv2Id.offsetTop;
   const eleTop3 = await transitionDiv3Id.offsetTop;
   const leftPosition1 = await windowTop-eleTop1;
   const leftPosition2 = await windowTop-eleTop2;
   const leftPosition3 = await windowTop-eleTop3;

   const movingDiv11Id = await document.getElementById("transitionMove11Id");
   const movingDiv12Id = await document.getElementById("transitionMove12Id");
   const movingDiv13Id = await document.getElementById("transitionMove13Id");

   const movingDiv21Id = await document.getElementById("transitionMove21Id");
   const movingDiv22Id = await document.getElementById("transitionMove22Id");
   const movingDiv23Id = await document.getElementById("transitionMove23Id");
   const movingDiv24Id = await document.getElementById("transitionMove24Id");
   const movingDiv25Id = await document.getElementById("transitionMove25Id");
   const movingDiv26Id = await document.getElementById("transitionMove26Id");
   const movingDiv27Id = await document.getElementById("transitionMove27Id");
   const movingDiv28Id = await document.getElementById("transitionMove28Id");
   const movingDiv210Id = await document.getElementById("transitionMove210Id");
   const movingDiv211Id = await document.getElementById("transitionMove211Id");

   const movingDiv31Id = await document.getElementById("transitionMove31Id");
   const movingDiv32Id = await document.getElementById("transitionMove32Id");
   const movingDiv33Id = await document.getElementById("transitionMove33Id");
   const movingDiv34Id = await document.getElementById("transitionMove34Id");
   const movingDiv35Id = await document.getElementById("transitionMove35Id");
   const movingDiv36Id = await document.getElementById("transitionMove36Id");
   const movingDiv37Id = await document.getElementById("transitionMove37Id");
   const movingDiv38Id = await document.getElementById("transitionMove38Id");
   const movingDiv310Id = await document.getElementById("transitionMove310Id");
   const movingDiv311Id = await document.getElementById("transitionMove311Id");
   const movingDiv312Id = await document.getElementById("transitionMove312Id");
   const movingDiv313Id = await document.getElementById("transitionMove313Id");
   const movingDiv314Id = await document.getElementById("transitionMove314Id");
   const movingDiv315Id = await document.getElementById("transitionMove315Id");
   const movingDiv316Id = await document.getElementById("transitionMove316Id");
   const movingDiv317Id = await document.getElementById("transitionMove317Id");
   const movingDiv318Id = await document.getElementById("transitionMove318Id");
   const movingDiv319Id = await document.getElementById("transitionMove319Id");
   const movingDiv320Id = await document.getElementById("transitionMove320Id");

   movingDiv11Id.style.left=leftPosition1+90+'px';
   movingDiv12Id.style.left=-leftPosition1+'px';
   movingDiv13Id.style.left=leftPosition1+'px';

   movingDiv21Id.style.left=leftPosition2+50+'px';
   movingDiv22Id.style.left=leftPosition2+20+'px';
   movingDiv23Id.style.left=leftPosition2+80+'px';
   movingDiv24Id.style.left=leftPosition2+70+'px';
   movingDiv25Id.style.left=leftPosition2+20+'px';
   movingDiv26Id.style.left=leftPosition2+50+'px';
   movingDiv27Id.style.left=leftPosition2+90+'px';
   movingDiv28Id.style.left=leftPosition2+100+'px';
   movingDiv210Id.style.left=leftPosition2+'px';
   movingDiv211Id.style.left=leftPosition2+'px';

   movingDiv31Id.style.left=leftPosition3+50+'px';
   movingDiv32Id.style.left=leftPosition3+20+'px';
   movingDiv33Id.style.left=leftPosition3+80+'px';
   movingDiv34Id.style.left=leftPosition3+70+'px';
   movingDiv35Id.style.left=leftPosition3+20+'px';
   movingDiv36Id.style.left=leftPosition3+50+'px';
   movingDiv37Id.style.left=leftPosition3+90+'px';
   movingDiv38Id.style.left=leftPosition3+100+'px';
   movingDiv310Id.style.left=leftPosition3+20+'px';
   movingDiv311Id.style.left=leftPosition3+50+'px';
   movingDiv312Id.style.left=leftPosition3+100+'px';
   movingDiv313Id.style.left=leftPosition3+40+'px';
   movingDiv314Id.style.left=leftPosition3+20+'px';
   movingDiv315Id.style.left=leftPosition3+'px';
   movingDiv316Id.style.left=leftPosition3+'px';
   movingDiv317Id.style.left=leftPosition3+'px';
   movingDiv318Id.style.left=leftPosition3+'px';
   movingDiv319Id.style.left=leftPosition3+'px';
   movingDiv320Id.style.left=leftPosition3+'px';
 }

 useEffect(()=>{
  async function handleScrollMove(){
    const divId =await document.getElementById("mainDiv");
    divId.addEventListener('scroll',()=>handleScroll(divId))
  }
  handleScrollMove();
 },[mainDivRef]);
  
  return (
    <div id="AppId"  className="App">
    <Parallax ref={mainDivRef} id='mainDiv' pages={5.4} style={{ top: '0', left: '0' ,width:'100vw',overflowY:'scroll',display:'flex',flexDirection:'column',alignItems:'center'}}>
      <ParallaxLayer
        offset={0}
        speed={2.5}
        >
        <Suspense fallback={<FallBack />}>
          <Hero />
        </Suspense>
      </ParallaxLayer>

      <ParallaxLayer offset={0.9} speed={0.3} >
        <Suspense fallback={<FallBack />}>
          <TransitionOne />
        </Suspense>
      </ParallaxLayer>

      <ParallaxLayer
        offset={1.5}
        speed={0.5}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}>
        <Suspense fallback={<FallBack />}>
          <Skills setInSkillComponent={setInSkillComponent} />
        </Suspense>
      </ParallaxLayer>

      <ParallaxLayer offset={2.2} speed={0.3} >
        <Suspense fallback={<FallBack />}>
          <TransitionTwo />
        </Suspense>
      </ParallaxLayer>

      <ParallaxLayer
        offset={2.9}
        speed={0.5}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}>
        <Timeline />
      </ParallaxLayer>

      <ParallaxLayer offset={3.8} speed={0.3} >
        <Suspense fallback={<FallBack />}>
          <TransitionThree />
        </Suspense>
      </ParallaxLayer>

      {<ParallaxLayer
        offset={4}
        speed={0.5}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'white',
        }}>
        <ErrorBoundary>
          <Suspense fallback={<FallBack />} >
            <div 
            style={{
              width:'100vw',
              height:'100vh'
            }}
            >
              <h2 style={{zIndex:'1'}} data-aos="fade-down" id="galleryH2" className="headGallery">Gallery</h2>
              <Gallery setSelected={setSelected} images={images} />
            </div>
          </Suspense>
        </ErrorBoundary>
      </ParallaxLayer> }
      <ParallaxLayer
        offset={4.8}
        // speed={0.5}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'black',
          height:'60vh',
          // backgroundColor:'green'
        }}>
        <Footer />
      </ParallaxLayer>
      <ParallaxLayer
        offset={4.8}
        speed={-0.2}
        // sticky={}
        style={{
          position:'absolute',
          left:'0',
          zIndex:'10',
          height:'30px',
          fontWeight:'bolder'
        }}>
        <Link className='aboutLink' to="/about">About me</Link>
      </ParallaxLayer>
    </Parallax>
    </div>
  );
}

export default Home;
