import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import NavBar from './components/navbar';
import About from './pages/about/About';
import Home from './pages/home/Home';
import NotFound from './pages/notfound/NotFound';
import Splash from './pages/splash/Splash';
import resume from "./assets/resume.pdf";

function App() {
  const [inSkillComponent,setInSkillComponent] = useState(false);
  const ajLogoRef = useRef();
  const [splash,setSplash] = useState(true);
  const [showSite,setShowSite] = useState(false);
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const handleSplashScreenRemove = ()=>{
    setTimeout(async()=>{
      const splashScreen = await document.getElementById("splashScreenId");
      document.getElementById('App').removeChild(splashScreen);
    },2000);
  }
  const handleSplashScreenZIndex = ()=>{
    setTimeout(async()=>{
      const splashScreen = await document.getElementById("splashScreenId");
      splashScreen.style.zIndex=-1;
      handleSplashScreenRemove();
  },500);
  };

  useEffect(()=>{
        setTimeout(()=>{
          // setSplash(false);
          handleSplashScreenZIndex();
        },6000);
      // })
  },[]);
  // useEffect(()=>{
  //   setTimeout(()=>{
  //     setShowSite(true);
  //   },2000);
  // },[]);

  useEffect(() => {
    // if(window.innerWidth <= 1288)
      // setShowMobileWarning(true)
  }, []);

  return (
    <div id="App" style={{width:'100vw',display:'flex',flexDirection:'column',alignItems:'center'}}>
      {
        splash &&
        <Splash splash={splash} setSplash={setSplash} setShowSite={setShowSite} />
      }
      {
        showSite &&
          <BrowserRouter >
            <NavBar inSkillComponent={inSkillComponent} ajLogoRef={ajLogoRef} />
            <Routes>
              <Route path="/" element={<Home ajLogoRef={ajLogoRef} inSkillComponent={inSkillComponent} setInSkillComponent={setInSkillComponent} />} exact />
              <Route path="/about" element={<About />} exact />
              <Route
                path="/resume"
                element={
                  <iframe src={resume} style={{width:'100vw',height:'100vh',position:'absolute',top:'0'}} title="resume">
                    Presss me: <a href={resume}>Download PDF</a>
                  </iframe>
                }
              />
              <Route path="*" exact={true} element={<NotFound />} />
            </Routes>
          </BrowserRouter>
          
         
        }
      
    
    
    </div>
  );
}

export default App;