import React from 'react';
import "./style.css";
function TimeLineObj(props) {
    const colorList=[
        "magenta",
        "cyan",
        "yellow"
    ];
    return (
        <div key={props.index} id={`${props.title}`} className={`obj`}>
            <div style={{display:'flex',flexDirection:'row',alignItems:'center',width:'100%',justifyContent:'space-between'}} className={``}>
                <div className={`${props.title===props.activeId?"activeLeft":"passive"}`} style={{
                   display:'flex',flexDirection:'column',width:'50px',justifyContent:'flex-start',height:'100%'
                }}>
                    <h5 style={{width:'100%',fontSize:'x-large',fontWeight:'bold'}}>{props.date}</h5>
                    <hr style={{width:'100%'}} />
                    <h4 style={{
                        fontWeight:'bold',
                        fontSize:'large'
                    }}>{props.SHtitle}</h4>
                    {/* ajx timeline logo */}
                    <img style={{
                        width:'100px',borderRadius:'8%',marginTop:'20px',filter: `drop-shadow(2px 2px 6px ${colorList[props.activeColorId]}) drop-shadow(2px 2px 0 ${colorList[props.activeColorId]})`,transition: 'filter 1s ease'
                    }} src={props.image} alt="logo" />
                </div>
                <div className={`objSub ${props.title===props.activeId?"activeRight":"passive"}`}>
                    <p style={{margin:'10px 0 0 0' }}>{props.writeUp}</p>
                    <hr style={{border: `1px solid ${colorList[props.activeColorId]}`,transition:"all 1s ease"}} className='seperate' />
                    <div style={{display:'grid',gridTemplateColumns: '1fr 1fr',columnGap:'1em'}}>
                        {props?.tech?.map((item,index)=>{
                            return (
                                <li key={index}>{item}</li>
                            )
                        })}
                    </div>
                    <p style={{margin:'10px 0 0 0' }}>{props.link}</p>
                </div>
           </div>
        </div>
    );
}

export default TimeLineObj;