import React,{useState,useEffect,useRef,useCallback} from 'react';
import { handleMailSend } from '../../services/mail';
import "./styles.css";

function Footer(props) {
    // const [loading,setLoading] = useState(false);
    const [errMssg,setErrMssg] = useState("");
    const [success,setSuccess] = useState(false);
    const [toSend, setToSend] = useState({
        from_name: '',
        to_name: 'abraham jose',
        message: '',
        reply_to: '',
      });
    const handleChange = (e) => {
        setErrMssg("");
        setToSend({ ...toSend, [e.target.name]: e.target.value });
    };

    const handleMssgSend = async(e)=>{
        e.preventDefault();
        if (toSend.from_name.length>0 && toSend.message.length>0)
        {
            // const res = await handleMailSend(toSend,setSuccess,setLoading);
            const res = await handleMailSend(toSend,setSuccess);
        }
        else if (toSend.from_name.length===0 || toSend.message.length===0)
            setErrMssg("All fields are mandatory");

    }

    var dataText = [ "I am always keen for a good hack, to advise and to engage in the internet, so feel free to say hi!"];
    async function typeWriter(text, i, fnCallback) {
        if (i < (text?.length)) {
        const writer = await document.getElementById("aboutMeTypewrite");
        if (writer)
            writer.innerHTML= text.substring(0, i+1) +'<span aria-hidden="true"></span>';

        setTimeout(function() {
            typeWriter(text, i + 1, fnCallback)
        }, 100);
        }
        else if (typeof fnCallback == 'function') {
            setTimeout(fnCallback, 100);
        }
    }
    const StartTextAnimation = useCallback((i) => {
        if (typeof dataText[i] == 'undefined'){
            setTimeout(function() {
            StartTextAnimation(0);
            }, 1000);
        }
        if (i < dataText[i]?.length) {
        typeWriter(dataText[i], 0, function(){
        StartTextAnimation(i + 1);
        });
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[typeWriter]);

    const ref = useRef();
    // const isVisible = useOnScreen(ref)

    useEffect(()=>{
        // if (isVisible)
            StartTextAnimation(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])


    return (
        <div ref={ref} className='footerContainer'>
           <div style={{width:'35%',maxHeight:'100%'}}>
               <p id="aboutMeTypewrite" className='aboutTextFooter'>Always keen for a good hack, to advise and to engage in the space and on the internet, so feel free to say hi!</p>
            </div>
            <div style={{
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                width:'30%',
            }}>
                <img className='mailImg' width={"50px"} src={`/images/footer/${success?"success":"email"}W.svg`} alt="email" />
            </div>
            <div className='mailDiv'>
                
                <div className='mailContents'>
                    <div className='itemMail'>
                        <label>Name</label>
                        <input type='text'
                            name='from_name'
                            placeholder='First name Last Name'
                            value={toSend.from_name}
                            onChange={(e)=>{handleChange(e)}} 
                        />
                    </div>
                   
                    <div className='itemMail'>
                        <label>Message</label>
                        <textarea 
                            name='message'
                            value={toSend.message}
                            onChange={(e)=>handleChange(e)} 
                            placeholder='What is in your mind? Make it interesting or keep it all business, I will treat them as equals.. 
                            Chances are, if you are not a SPAM, I will get back to you as soon as I could.' />
                    </div>
                    
                    <p style={{height:'10px',width:'100%',color:'magenta',fontWeight:'bold',marginBottom:'30px'}}>{errMssg}</p>
                    <div style={{display:'flex',justifyContent:'space-between',width:'70%',alignItems:'center',}}>
                        <button
                                className='submitMail'
                                to={'#'} 
                                target="_blank"
                                onClick={handleMssgSend}
                        >
                            Submit
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default Footer;