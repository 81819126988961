import "./style.css";
import data from '../../constants/data'
import React, { useState, useEffect, useRef } from 'react'
import TimeLineObj from "./TimeLineObj";

const ScrollMeter = ({progress,activeId,activeColorId}) => {

  const datas = data.timeline;
  const colorList=[
    "magenta",
    "cyan",
    "yellow"
  ];
  return(
    <div 
      className="meterDiv">
      <svg 
        className="meter"
        viewBox="0 0 10 100" 
        xmlns="http://www.w3.org/2000/svg"
      >
        {/* ajx timeline line */}
        <svg id="line" className="lineSvg" viewBox="0 0 29 453" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d_3_2)">
        <rect x="15" y="11" width="2" height="433" rx="1" fill="url(#paint0_linear_3_2)"/>
        </g>
        <defs>
        <filter id="filter0_d_3_2" x="0" y="0" width="32" height="463" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feMorphology radius="5" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_3_2"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="5"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3_2"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3_2" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_3_2" x1="16" y1="11" x2="16" y2="444" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white"/>
        </linearGradient>
        </defs>
        </svg>

        {/* ajx time line circle */}
        <circle
        className="timeLineDot" 
        style={{transform:`translate(0px, ${(progress)*100}%)`,boxShadow:`0 0 0 90px ${colorList[activeColorId]}`,fill:`${colorList[activeColorId]}`,stroke:`${colorList[activeColorId]}`,transition: 'color 1s ease, fill 1s ease,stroke 1s ease,filter 1s ease',filter:`drop-shadow(0px 0px 2px ${colorList[activeColorId]})`}} 
          cx="5.4"
          cy="2"
          r="1" 
          
        />
      </svg>
      <div 
      className="demoDiv">
        {datas.map((data,ind)=>{
          return (
            <button
              key={ind}
              className="demoObj"
              onClick={(e)=>{e.preventDefault()
                var elem = document.getElementById(data.title);
                var scrollDiv = document.getElementById("scrollDivId");
                var topPos = elem.offsetTop;
                document.getElementById('scrollDivId').scrollTop = topPos-(scrollDiv.clientHeight/10);
              }}
            >
              <h6 className={`${data.title===activeId?"activeTitle":"passiveTitle"}`} style={{textAlign:'right',width:'200px',color:data.title===activeId && `${colorList[activeColorId]}`,transition: 'color 1s ease,text-shadow 1s ease,font-size 500ms ease',textShadow:`0 30px 70px magenta, 0 0 50px ${colorList[activeColorId]}`}}>{data.title}</h6>
            </button>
          )
        })}
      </div>
    </div>
    )
    } 

const Timeline = () => {
  const datas = data.timeline;
  const [activeId,setActiveId] = useState("");
  const [activeColorId,setActiveColorId] = useState(0);
  var elems = document.querySelectorAll('.obj');
  const timelineRef = useRef();

  // const isVisible=useOnScreen(timelineRef);
  useEffect(()=>{
    async function getElement(){
      var elemnt =await document.querySelectorAll('.obj');
      const id = await elemnt[0].getAttribute('id');
      setActiveId(id)
    }
    getElement();
      
  },[]);

  const [scrollProgress, setScrollProgress] = useState(0),
    onScroll = ({ target: { scrollTop, scrollHeight } }) =>
      {
        var line = document.getElementById("line");
        var div = document.getElementById("scrollDivId");
        var divHeight = div.clientHeight;
        var height = line.clientHeight;
        setScrollProgress(((scrollTop+(divHeight/4)) / (scrollHeight-height)));

       elems.forEach(element => {
         const elemTop = element.offsetTop;
        //  const elemHeight = element.clientHeight;
         
         if (div.scrollTop+(div.clientHeight/1.5)>=elemTop){
           const currentId = element.getAttribute('id');
           setActiveId(currentId);
           setActiveColorId(Math.floor(Math.random()*4));
         }
       });
      }
  return (
    <div ref={timelineRef} className="mainDiv">
        <h2 data-aos="fade-down" className="timelineHead">What I have done so far</h2>
        <div id="contentDiv" style={{
          display:'flex',flexDirection:'row',alignItems:'center',height:'70vh'
        }}>
      <ScrollMeter activeColorId={activeColorId} activeId={activeId} progress={scrollProgress} />
      <div
        data-aos="fade-up"
        id="scrollDivId"
        className="scrollDiv" 
        onScroll={onScroll}
      >
        {datas.map((item,ind)=>{
            return (
                <TimeLineObj activeColorId={activeColorId} key={ind} activeId={activeId} SHtitle={item.SHtitle} title={item.title} image={item.image} index={ind} date={item.date} writeUp = {item.writeUp} tech ={item.tech} link={item.link} />
            )
        })}
      </div>
      </div>
    </div>
  )
}

export default Timeline;