import React from 'react';

function NotFound(props) {
    return (
        <div style={{
            minWidth:'100vw',
            minHeight:'100vh',
            overflow:'hidden',
            display:'flex',
            justifyContent:'center',
            alignItems:"center",
            backgroundColor:"black",
            position:'absolute'
        }}>
            <p style={{fontWeight:'bold',color:'white'}}>Page not found</p>
        </div>
    );
}

export default NotFound;