import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import "./styles.css";
function NavBar({ajLogoRef,inSkillComponent}) {
    return (
        <div id="navId"  className='navBar'>
            <img alt="aj" width={40} src="/images/w2_f.png" id="navLogoId" className='logo' style={{opacity:inSkillComponent?'0':'1'}} ref={ajLogoRef} />
            {
                inSkillComponent &&
                <img width={40} alt="aj" src="/images/g4.png" style={{color:'yellow'}} className='logo'/>
            }
            <Link style={{color:useMatch("/")?"yellow":'white'}} to="/" className='home'>HOME</Link>
            <Link style={{color:useMatch("/about")?"black":'white'}} to="/about" className='about'>ABOUT</Link>
            <Link style={{color:useMatch("/resume")?"magenta":'white'}} to="/resume" className='resume'>RESUME</Link>
        </div>
      
    );
}

export default NavBar;