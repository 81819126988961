import React from 'react';
import "./About.css";
function About() {
    return (
        <div className='aboutContainer'>
            <p style={{fontSize:'2rem'}}><br /><br /><br /><span style={{minWidth:'100%',float:'left',textAlign:'left'}}>hello,</span><br/><br />
            I enjoy the pursuit of craft.
            <br /><br />
            Born as a late talker, I learned to have a heightened sense of visual cognition from my childhood which helped me earn curiosity in understanding, passionately pursuing and experiencing the field of imaging, image understanding(conventional and deep learning), 3D geometry and animation.
            <br /><br /> 
            My exposure to industrial and academic settings for the past 5 years has made me a pragmatic, versatile and a savvy research engineer who is able to reimagine and engineer products achieving tangible objectives setting up strategic milestones. My base is a strong mathematical foundation(mom's 🧬) in digital/analog systems and the reputation I garnered as a computer geek leader(dad's 🧬) during my engineering, which laid a solid foundation in programming, image processing and with robots(electronics/sensors/actuators/design). I was psyched to complete my Autonomous Rover project with Jetson TX1, my means to challenge nvidia's autonomous car, BB8(I used BB8 images in our presentation though) after my automated wastebasket mini-project. .
            <br /><br /> 
            I am grateful for the opportunity to do research under professor Abhijit Mahalanobis, in CRCV, with some wonderful colleagues and complete my masters in Computer Science attending as many courses I could find(that’s 9/12 of all courses I took in 2 years!) in computer vision, machine learning and extended reality. 
            <br /><br />       
            I believe in the human spirit and value empathy, integrity and creativity above all else as they stand as mine. So I enjoy being surrounded by brilliant minds and love a challenging learning environment.  I am an optimist and certainly believe that we would achieve true image understanding and realize conciseness. 
            <br /><br /> 
            For an amphibian, I try to do a bit of everything as much of my inspiration, creativity and challenges comes from the experience with people and nature around me. You could find me in a soccer field, bringing soul to a recipe while trying to interpret cultural relevance of a dish, dribbling a basketball, flexing at the beach or running on the sidewalks hoping I'd finish a full marathon someday. I am not afraid to try anything for once. My life’s been a bliss so far.
            <br /><br />
            There’s a certain ‘thing’ in life that you can’t put your finger at nor your eyes could ever meet. I will be in the pursuit of that in the meantime.
            <br /><br />


<span style={{width:'100%',float:'right',textAlign:'right'}}> - yet another engineer.</span>
            </p>
        </div>
    );
}

export default About;