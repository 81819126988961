import {send} from "@emailjs/browser";
export const handleMailSend = async(toSend,setSuccess)=>{

    // setLoading(true);
    send(process.env.REACT_APP_EMAILJS_SERVICE_ID,
    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    toSend,
    process.env.REACT_APP_EMAILJS_USER_ID)
    .then((res)=>{
        setSuccess(true);
        // setLoading(false);
        return {message:"success"}
    })
   .catch(err=>{
       setSuccess(false);
    //    setLoading(false);
       alert("Something went wrong.Message not send");
       return{message:"error"}
   })
    
};