import React,{useEffect,useState} from 'react';
import "./styles.css";

function handleSplash(setSplash,setShowSite){
  setTimeout(()=>{
      setSplash(false);
      setShowSite(true);
  },2000);
}

function Splash({splash=true,setSplash,setShowSite}) {
    const [showText,setShowText] = useState(false);

    var dataText = [ "Hey,", "you are", "Welcome :)"];
    async function typeWriter(text, i, fnCallback) {
    if (i < (text?.length)) {
     const writer = await document.getElementById("SplashTypeWriter");
     if (writer)
     {
        writer.innerHTML= text.substring(0, i+1) +'<span aria-hidden="true"></span>';
        if (writer.innerHTML==='Welcome :)<span aria-hidden="true"></span>')
        {
            const screen =await document.getElementById("splashScreenId");
            setTimeout(()=>{
              screen.classList.add('fadeOutSplash');
              handleSplash(setSplash,setShowSite);
            },1000);
        }
      }
      setTimeout(function() {
        typeWriter(text, i + 1, fnCallback)
      }, 120);
    }
    else if (typeof fnCallback == 'function') {
      setTimeout(fnCallback, 100);
        }
    }
    function StartTextAnimation(i) {
        if (i < dataText[i]?.length) {
        typeWriter(dataText[i], 0, function(){
        StartTextAnimation(i + 1);
        });
        }
    }
    useEffect(()=>{
        setTimeout(()=>{
            setShowText(true);
            StartTextAnimation(0);

        },1400);
       
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    return (
      <div id='splashScreenId' className={`splashContainer fadeInSplash`}>
          <img id="splashScreenLogoId" className='TransitionLogo introLogo' alt="AJ" src="/images/w2_f.png" />
          {
              showText &&
                  <h2 id="SplashTypeWriter" className="titleSplash">Hi</h2>
          }

      </div>
    );
}

export default Splash;